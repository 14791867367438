<template>
  <Breadcrumb />
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onEditButtonClick="onEditButtonClick"
    @onBusinessRuleActionButtonClick="onBusinessRuleActionButtonClick"
  />
</template>
<script>
export default {
  name: "DesignBusinessRuleList",
  data() {
    return {
      gridSettings: {
        action: "DesignBusinessRuleList",
        requestUrl: "/Brs-BusinessRuleList",
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],
        isGatewayRequest: true,

        allowSearchPanel: false,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        autoOrderProcess: true,

        buttons: [
          {
            name: "action",
            cssClass: "btn-primary",
            iconClass: "bi-lightning-fill",
            routeButton: false,
            emitMethodName: "onBusinessRuleActionButtonClick",
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "BusinessRule.CustomObjectName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "customObjectFormulaName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRule.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRule.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRule.BusinessProcess",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "businessProcess",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRule.BusinessRuleTriggerType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "businessRuleTriggerType",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onEditButtonClick(rowData) {
      let route = this.$router.resolve({
        name: "CustomObjectEditBusinessRule",
        params: {
          customObjectId: rowData.customObjectPublicId,
          businessRuleId: rowData.publicId,
        },
      });
      window.open(route.href);
    },
    onBusinessRuleActionButtonClick(rowData) {
      localStorage.setItem(
        "businessRuleTriggerTypeId",
        rowData.businessRuleTriggerTypeId
      );
      this.$router.push({
        name: "CustomObjectBusinessRuleActions",
        params: {
          customObjectId: rowData.customObjectPublicId,
          businessRuleId: rowData.publicId,
        },
      });
    },
  },
  mounted() {},
};
</script>
